.dfl_main_heading_Section {
	padding: 60px 0 0;
}
.dfl_main_heading_Section h1 {
	font-size: 50px;
	line-height: 50px;
	font-weight: 400;
	margin-bottom: 0;
}
.dfl_holiday_bg_banner{
    background-image: url('../../Assets/images/DFL/gift-guide-banner.webp');
    width: 100%;
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}
.dfl_gift_pera a {
	text-decoration: underline;
	color: #7a98a0;
	font-weight: 500;
}
.dfl_holiday_heading_section {
	padding: 8px 0;
	background-color: #ba231b;
}
.dfl_holyday_heading_main a {
	font-size: 16px;
	line-height: 25px;
	color: #fff;
	font-weight: 600;
	text-decoration: underline;
}
.dfl_holiday_bg_banner h1{
    font-size: 50px;
    line-height: 50px;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
}
.dfl_holiday_bg_banner h6{
    font-size: 24px;
    line-height: 30px;
    color: #000;
    font-weight: 600;
}
/************* gift guide ****************/
.dfl_gift_heading {
	font-size: 44px;
	line-height: 50px;
	color: #000;
	font-weight: 600;
	text-transform: capitalize;
	text-align: center;
	margin-bottom: 20px;
	font-family: 'Inter', sans-serif !important;
}
.dfl_gift_subheading{
    font-size: 38px;
    line-height: 40px;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 20px;
   font-family: 'Inter', sans-serif !important;
   
}
.dfl_gift_pera {
	font-size: 18px;
	line-height: 25px;
	color: #000;
	font-weight: 400;
	text-align: center;
	font-family: 'Inter', sans-serif !important;
	padding-bottom: 0;
}
.dfl_gift_pera.pera_width {
	width: 50%;
	margin: 0 auto;
}
.dfl_holiday_gift_guide, .dfl_gift_ideas_section {
	padding: 80px 0;
}
.dfl_gift_ideas_section{
    background-color:#FAFAFA ;
}
.dfl_gift_idea_img_box img {
    margin: 30px auto;
    cursor: pointer;
}
.dfl_gift_custom_container{
    width: 100%;
    max-width: 1200px;
}
.dfl_gift_ideas_section .dfl_gift_pera {
    width: 90%;
}
.dfl_giftcolor_greay_text{
    color: #B9B9B9;
}
.dfl_gift_bottom_text_light_bg_section{
    background-color: #E2E9E9;
    text-align: center;
}
.dfl_gift_bottom_text_light_bg_section a{
    color: #7A98A0;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}


@media(min-width:320px) and (max-width:767px){
    .dfl_main_heading_Section h1 {
        font-size: 30px;
        line-height: 32px;
    }
    .dfl_main_heading_Section {
        padding: 30px 0 0;
    }
    .dfl_gift_order1{
        order: 2;
    }
    .dfl_gift_order2{
        order: 1;
    }
    .dfl_holyday_heading_main a {
        font-size: 14px;
        line-height: 22px;
    }
    .dfl_holiday_heading_section {
        padding: 5px 0;
    }
    .dfl_holiday_bg_banner {
        background-image: url('../../Assets//images/DFL/gift-banner-mobile.webp');
        height: 270px;
        position: relative;
    }
    .dfl_holiday_bg_banner::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: #acbfc333;
    }
    .dfl_holiday_bg_banner h6 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }
    .dfl_holiday_bg_banner h1 {
        font-size: 35px;
        line-height: 35px;
        text-align: center;
    }
    .dfl_gift_heading {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 15px;
    }
    .dfl_gift_pera.pera_width {
        width: 100%;
    }
    .dfl_gift_pera {
        font-size: 15px;
        line-height: 23px;
    }
    .dfl_holiday_gift_guide, .dfl_gift_ideas_section {
        padding: 40px 0;
    }
    .dfl_gift_subheading {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 15px;
        text-align: center;
    }
    .dfl_gift_ideas_section .dfl_gift_pera {
        width: 100%;
        text-align: center !important;
    }
}
@media(min-width:768px) and (max-width:991px){
    .dfl_main_heading_Section h1 {
        font-size: 35px;
        line-height: 35px;
    }
    .dfl_main_heading_Section {
        padding: 30px 0 0;
    }
    .dfl_holiday_heading_section {
        padding: 4px 0;
    }
    .dfl_holiday_bg_banner {
        height: 220px;
    }
    .dfl_holiday_bg_banner h6 {
        font-size: 20px;
        line-height: 25px;
    }
    .dfl_holiday_bg_banner h1 {
        font-size: 35px;
        line-height: 35px;
    }
    .dfl_gift_heading {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .dfl_gift_pera.pera_width {
        width: 90%;
    }
    .dfl_gift_pera {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 10px;
    }
    .dfl_holiday_gift_guide, .dfl_gift_ideas_section {
        padding: 50px 0;
    }
    .dfl_gift_subheading {
        font-size: 25px;
        line-height: 23px;
        margin-bottom: 15px;
    }
    .dfl_gift_bottom_text_light_bg_section a {
        font-size: 16px;
        line-height: 23px;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .dfl_main_heading_Section h1 {
        font-size: 40px;
        line-height: 40px;
    }
    .dfl_main_heading_Section {
        padding: 50px 0 10px;
    }
    .dfl_holiday_heading_section {
        padding: 4px 0;
    }
    .dfl_holiday_bg_banner {
        height: 260px;
    }
    .dfl_holiday_bg_banner h6 {
        font-size: 20px;
        line-height: 25px;
    }
    .dfl_holiday_bg_banner h1 {
        font-size: 35px;
        line-height: 35px;
    }
    .dfl_gift_heading {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .dfl_gift_pera.pera_width {
        width: 90%;
    }
    .dfl_gift_pera {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 10px;
    }
    .dfl_holiday_gift_guide, .dfl_gift_ideas_section {
        padding: 50px 0;
    }
    .dfl_gift_subheading {
        font-size: 25px;
        line-height: 23px;
        margin-bottom: 15px;
    }
    .dfl_gift_bottom_text_light_bg_section a {
        font-size: 16px;
        line-height: 23px;
    }
}
@media(min-width:1025px) and (max-width:1199px){
    .dfl_holiday_bg_banner {
        height: 330px;
    }
}
@media(min-width:1200px) and (max-width:1440px){
    .dfl_main_heading_Section h1 {
        font-size: 38px;
        line-height: 38px;
    }
    .dfl_main_heading_Section {
        padding: 40px 0 0;
    }
    .dfl_holiday_heading_section {
        padding: 4px 0;
    }
    .dfl_holyday_heading_main a {
        font-size: 14px;
        line-height: 22px;
    }
    .dfl_holiday_bg_banner {
        height: 330px;
    }
    .dfl_holiday_bg_banner h6 {
        font-size: 20px;
        line-height: 25px;
    }
    .dfl_holiday_bg_banner h1 {
        font-size: 40px;
        line-height: 40px;
    }
    .dfl_gift_heading {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 15px;
    }
    .dfl_gift_pera {
        font-size: 16px;
        line-height: 23px;
    }
    .dfl_gift_subheading {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .dfl_gift_idea_img_box img {
        margin: 20px auto;
    }
}