   .dfl_tranding_new_design_outer .owl-item.active.center img {
	transform: scale(1.5);
	transition: all .4s ease-in-out;
}
.dfl_tranding_new_design_outer .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	background: none;
	color: #7A98A0;
	border: none;
	padding: 0 !important;
	font: inherit;
	margin: 0 15px;
	font-size: 55px;
	transform: rotateX(0);
	transition: all .4s ease-in-out;
}

.dfl_tranding_new_design_outer .owl-carousel .owl-nav button.owl-next:hover{
    background: transparent !important;
     transform: translateX(15px);
     transition: all .4s ease-in-out;
     color: #7A98A0;
}
.dfl_tranding_new_design_outer .owl-carousel .owl-nav button.owl-prev:hover {
    background: transparent !important;
     transform: translateX(-15px);
     transition: all .4s ease-in-out;
    color: #7A98A0;
}
.dfl_tranding_slide_img_outer_box {
	padding: 20px 30px;
}
.dfl_tranding_slide_img_outer_box img {
	width: 95% !important;
	height: auto;
	margin: 0 auto;
}
.dfl_tranding_new_design_outer.mt-5 {
	background-color: #fff;
	padding: 60px 0 20px 0;
}


@media(min-width:320px) and (max-width:767px){
	.dfl_tranding_slide_img_outer_box {
	padding: 0;
}
.dfl_tranding_slide_img_outer_box img {
	width: 50% !important;
}
   .dfl_tranding_new_design_outer .owl-item.active.center img {
	transform: scale(1.4);
}
.dfl_tranding_new_design_outer .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	margin: 0 10px;
	font-size: 30px;
}
.dfl_tranding_new_design_outer{
	padding: 20px 0 10px 0;
}
.dfl_tranding_heafding_text h2 {
	margin-bottom: 0 !important;
	margin-top: 20px;
}
.dfl_tranding_heafding_text .title-box2 {
	margin-bottom: 0 !important;
}
.dfl_tranding_new_design_outer {
	margin-top: 20px !important;
}
}

@media(min-width:768px) and (max-width:1023px){
	.dfl_tranding_new_design_outer .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	margin: 0 5px;
	font-size: 25px;
}
.dfl_tranding_slide_img_outer_box {
	padding: 0px 5px;
}
.dfl_tranding_new_design_outer{
	padding: 40px 0 10px 0 !important;
}
.dfl_tranding_slide_img_outer_box img {
	width: 65% !important;
}
   .dfl_tranding_new_design_outer .owl-item.active.center img {
	transform: scale(1.4);
}
}
@media(min-width:1024px) and (max-width:1199px){
	.dfl_tranding_new_design_outer .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	margin: 0 5px;
	font-size: 25px;
}
.dfl_tranding_slide_img_outer_box {
	padding: 0px 5px;
}
.dfl_tranding_new_design_outer{
	padding: 40px 0 10px 0 !important;
}
}
@media(min-width:1200px) and (max-width:1440px){
.dfl_tranding_new_design_outer{
	padding: 30px 0 10px 0 !important;
}
.dfl_tranding_new_design_outer .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	font-size: 35px;
	margin: 0 15px;
}



}