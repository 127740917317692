.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.skh span {
  transform: scale(1, 1) !important;
  height: 16vh !important;
}
.sk span {
  transform: scale(1, 1) !important;
  height: 100vh;
}
.mb60{
  margin-bottom: 60px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.hideIcon img {
  display: none;
}
body .hideIcon {
  padding: 0px !important;
}
body .hideIcon:hover,body .hideIcon:focus {
  padding: 0px !important;
}
.hideIcon span#loginbutton{
  padding: 10px 40px;
}

body[class^="/invoice/"] #rcs_header_seaction,body[class^="/invoice/"] #footer,body[class^="/invoice/"] .dfl_wholesale {
display: none !important;
}
.react-datepicker__input-container input {
  border: 1px solid #d5d5d5;
  height: 47px;
  border-radius: 5px;
  width: 100%;
  padding: 0 12px;
  outline: none;
}
.react-datepicker__input-container input {
border-color: #c4c4c4;

}
.react-datepicker__month-container {
  z-index: 99999999999 !important;
  position: relative;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  z-index: 0;
}
.preloader {
  z-index: 99999;
  background: rgba(0,0,0,0.3);
}
.preloader img {
  width: 15%;
}
.fs20{
  font-size: 20px;
}
.fs40{
  font-size: 40px;
}
.\/custom-design .rcs_customDesgin_banner::before{
  display: none;
}

.\/custom-design  .rcs_customDesgin_banner h2{
  color: #000;
}
.object-fit-cover video {
  object-fit: cover;
}

@media only screen and (max-width:767px){
  .rcs_home_video_main .object-fit-cover {
    height: auto !important;
  }
}